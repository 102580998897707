<template>
  <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form>
        <a-form-item label="规格" prop="specList">
          <a-select placeholder="请选择规格" style="width: 100%" v-model="queryParam.specId" allowClear>
            <a-select-option v-for="spec in form.specList" :value="spec.id" :key="spec.id" >
              规格名:{{spec.spec}}   原始库存: {{ spec.stock}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
    </a-form>
    <div>
      <a-alert :message="`当前选择月份: ${selectedValue && selectedValue.format('YYYY-MM')}`"/>
      <a-calendar :value="calendarValue" @select="onSelect" @change="onChange" @panelChange="onPanelChange">
        <ul style="height: 100%;" slot="dateCellRender" slot-scope="value" class="events">
          <li style="height: 100%;" v-for="item in getListData(value)" :key="item.localDate" :style="{backgroundColor:item.isHaveStock?'#87CEFA':'#F08080'}">
            剩余库存:  {{ item.stock}}
          </li>
        </ul>
        <template slot="monthCellRender" slot-scope="value">
          <div v-if="getMonthData(value)" class="notes-month">
            <section>{{ getMonthData(value) }}</section>
            <span>Backlog number</span>
          </div>
        </template>
      </a-calendar>
    </div>
  </a-drawer>
</template>

<script>
import {getAdvert, getStock} from '@/api/res/advert'
import moment from 'moment';


export default {
  name: 'calendar',
  props: {
  },
  components: {
  },
  data () {
    return {
      calendarValue: moment(new Date()),
      selectedValue: moment(new Date()),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      dataList:[],
      // 表单参数
      form: {
        id: null,
        specList:[]

      },
      // 查询参数
      queryParam: {
        specId: null,
        month: null,
        id:null,
      },
      formType: 1,
      open: false,
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onSelect(value) {
      this.calendarValue = value;
      this.selectedValue = value;
    },
    getDefault() {
      return moment(new Date())
    },
    onChange(value) {
      this.calendarValue = value
      this.selectedValue = value;
      this.handleQuery()
    },
    onPanelChange(value) {
      this.calendarValue = value
      this.selectedValue = value;
    },
    onClose () {
      this.open = false
      this.dataList=[]
      this.form.specList=[]
      this.form.id = null
      this.queryParam={}
    },
    getCalendar(record) {
      this.open = true
      this.formTitle = '库存'
      //查询套餐,根据套餐
      getAdvert({"id":record.id}).then(response => {
        this.form = response.data
        this.queryParam.id = response.data.id
        let idx = 1;
        this.form.specList.forEach(e=>{
          e.index= idx;
          idx++;
        })
      })
    },

    // 取消按钮
    cancel () {
      this.open = false
    },
    /** 搜索按钮操作 */
    handleQuery () {
      if(!this.queryParam.specId) return
      let data = {
        id:this.queryParam.id,
        specId: this.queryParam.specId,
        month :this.selectedValue.format('YYYY-MM')
      }
      getStock(data).then(response => {
        this.dataList = response.data
      })
    },
    getListData(value) {
      let dateformat = value.format('YYYY-MM-DD');
      let list = []
      this.dataList.forEach(e=>{
        if (e.localDate == dateformat) {
          list.push(e)
        }
      });
      return list;
    },
    getMonthData(value) {
      console.log('月份??',value)
    },

  }
}
</script>
<style scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>